import { Backdrop, Button, CircularProgress, Paper } from "@mui/material";
import { cleanup, getSuppliers, getClassifications, deleteSuppliers } from "@/reducers/supplier/supplierAction";
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import AddIcon from '@mui/icons-material/Add';
import { Col, Container, Row } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from '@/shared/components/ConfirmationDialog';
import { CardPage, CardPageBody, CardPageHeader, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import MaterialAssetInfoModal from "../../AssetManagement/AssetInventory/components/materialAssetInfoModal";

const Supplier = () => {

  const dispatch = useDispatch();

  const {
    suppliers, isLoadingSuppliers, getSuppliersError,
    isDeletingSuppliers, deletedSuppliers, deleteSuppliersError,
    classifications, isLoadingClassifications, getClassificationsError
  } = useSelector(({ suppliers }) => suppliers);

  const [selectedRows, setSelectedRows] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState({ show: false, assetId: null });

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSuppliers());
    dispatch(getClassifications());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  const onClickCreate = () => {
    navigate("Create");
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState(null);

  useEffect(() => {
    if (getClassificationsError) {
      enqueueSnackbar(getClassificationsError, { variant: "error" });
    }
  }, [getClassificationsError]);

  useEffect(() => {
    if (getSuppliersError) {
      enqueueSnackbar(getSuppliersError, { variant: "error" });
    }
  }, [getSuppliersError]);

  useEffect(() => {
    if (deleteSuppliersError) {
      enqueueSnackbar(deleteSuppliersError, { variant: "error" });
    }
  }, [deleteSuppliersError]);

  const onHideInfoModal = () => {
    setShowInfoModal({ show: false, assetId: null });
  };

  const openInfoModal = (selectedStakeholder) => {
    setShowInfoModal({ show: true, assetId: selectedStakeholder.assetId });
  };

  const handleClickDeleteSupplier = (supplier) => {
    setSupplierToDelete(supplier);
    setOpenConfirmDelete(true);
  }

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    dispatch(deleteSuppliers(supplierToDelete.stakeholderId));
    setSupplierToDelete(null);
  }

  const handleOnCloseDelete = () => {
    setOpenConfirmDelete(false);
    setSupplierToDelete(null);
  };

  useEffect(() => {
    if (deletedSuppliers) {
      enqueueSnackbar("Stakeholder deleted successfully", { variant: "success" });
      dispatch(getSuppliers());
    }
  }, [deletedSuppliers]);

  const handleClickEditSupplier = (supplier) => {
    navigate("Update/" + supplier.stakeholderId);
  }

  const handleClickSupplierUsers = (supplier) => {
  }

  const mapSuppliers = (suppliers) => {
    var data = JSON.parse(JSON.stringify(suppliers));
    data.forEach(d => d.stakeholderTypeName = d.stakeholderType?.name);
    return data;
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 350,
      flex: 1,
    },
    {
      field: 'stakeholderType',
      valueGetter: (params) => params.value.name,
      headerName: 'Type',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        if (params.row.isSystem) {
          return [];
        }

        return [
          <GridActionsCellItem
            icon={<GroupIcon />}
            label="Users"
            showInMenu
            onClick={() => handleClickSupplierUsers(params.row)}
          />,
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="View"
            showInMenu
            onClick={() => openInfoModal(params.row)}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            showInMenu
            onClick={() => handleClickEditSupplier(params.row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            showInMenu
            onClick={() => handleClickDeleteSupplier(params.row)}
          />
        ];
      },
    },
  ];

  return (
    <Container>
      <CardPage>
        <CardPageBody>
          <CardPageHeader>
            <CardPageTitleWrap>
              <CardPageTitle>Stakeholders</CardPageTitle>
              <CardPageSubhead>
                Here you can view your Stakeholders.
              </CardPageSubhead>
            </CardPageTitleWrap>
            <CardPageButtonWrap>
              <Button variant="contained" color="primary" onClick={onClickCreate} endIcon={<AddIcon />}>Add Stakeholder</Button>
            </CardPageButtonWrap>
          </CardPageHeader>
          <CardPageGridWrap>
            <DataGrid
              autoHeight
              density="standard"
              disableRowSelectionOnClick
              rowSelectionModel={selectedRows}
              loading={isLoadingSuppliers}
              rows={suppliers}
              getRowId={(row) => row.stakeholderId}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true
                },
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
            />
          </CardPageGridWrap>

          {openConfirmDelete &&
            <ConfirmationDialog
              open={openConfirmDelete}
              onConfirm={handleConfirmDelete}
              onClose={handleOnCloseDelete}
              title="Delete Stakeholder"
              body={`Are you sure you want to delete ${supplierToDelete.name}?`}
              color='error'
              confirmButtonText='Delete'
            />
          }

          {showInfoModal.show &&
            <MaterialAssetInfoModal
              onHideModal={onHideInfoModal}
              showModal={showInfoModal.show}
              assetId={showInfoModal.assetId}
              classifications={classifications}
            />
          }

        </CardPageBody>
      </CardPage>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDeletingSuppliers}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

export default Supplier;
