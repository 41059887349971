import {Chip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FormTextField} from "../../shared/components/form/material-controls/TextField";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {DataGrid, GridActionsCellItem, gridClasses, GridToolbar} from "@mui/x-data-grid";
import {enqueueSnackbar} from "notistack";
import {searchScreen} from "../../reducers/cleverChainScreen/cleverChainScreenAction";
import SearchIcon from "mdi-react/SearchIcon";
import {Button} from "@/shared/components/Button";
import dayjs from "dayjs";
import CleverChainScreenInfoModal from "./components/CleverChainScreenInfoModal";

const CleverChainScreen = () => {
    const dispatch = useDispatch();
    const { isLoading, screenError, searchedScreen, screens } = useSelector(({ cleverChainScreen }) => cleverChainScreen);
    const { handleSubmit, reset, control } = useForm();
    const [ selectedRow, setSelectedRow ] = useState({});
    const [ showInfoModal, setShowInfoModal ] = useState(false);

    const renderChip = (data) => {
        let style; //: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'default';
         switch (data.value) {
             case 'Not Available':
                 style = 'info';
                 break;
             case 'Exact':
                 style = 'primary';
                 break;
             case 'Very High':
             case 'High':
                 style = 'error';
                 break;
             case 'Medium':
                 style = 'warning';
                 break;
             case 'Low':
             case 'Very Low':
                 style = 'success';
                 break;
             case 'All':
             default:
                 style = 'primary';
                 break;
         }
        return (
            <>
                { style === 'primary' &&
                    <Chip label={data.value} color='primary' size="small"/>
                }
                { style === 'error' &&
                    <Chip label={data.value} color='error' size="small"/>
                }
                { style === 'info' &&
                    <Chip label={data.value} color='info' size="small"/>
                }
                { style === 'success' &&
                    <Chip label={data.value} color='success' size="small"/>
                }
                { style === 'warning' &&
                    <Chip label={data.value} color='warning' size="small"/>
                }
            </>
        );
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
        },
        {
            field: 'database',
            headerName: 'Database',
            flex: 1,
        },
        {
            field: 'entity_type',
            headerName: 'Entity Type',
            flex: 1,
        },
        {
            field: 'match',
            headerName: 'Match',
            flex: 1,
            renderCell: renderChip
        },
        {
            field: 'subcategory',
            headerName: 'SubCategory',
            flex: 1,
        },
        {
            field: 'updated_at',
            headerName: 'Updated',
            flex: 1,
            valueFormatter: (params) => params.value? dayjs(params.value).format("DD/MM/YYYY") : ""
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<SearchIcon />}
                        label="Detail"
                        onClick={() => handleClickViewScreenInfo(params.row)}
                    />,
                ];
            },
        }
    ];

    useEffect(() => {
        if (screenError) {
            enqueueSnackbar(screenError, {variant: "error"});
        }
    }, [screenError]);

    useEffect(() => {
        if (searchedScreen) {
            enqueueSnackbar(searchedScreen, {variant: "success"});
        }
    }, [searchedScreen]);

    const checkFilters = () => {
        return control?.getFieldState('name').invalid;
    }

    const handleClickApplyFilters = () => {
        if (checkFilters()) {
            return;
        }

        let keyValues = Object.assign({}, control._formValues);

        for(let prop in keyValues) {
            if (!keyValues[prop])
                delete keyValues[prop];
        }

        dispatch(searchScreen(keyValues));
    }

    const handleClickViewScreenInfo = (row) => {
        setSelectedRow(row);
        setShowInfoModal(true);
    }

    const onHideInfoModal = () => {
        setShowInfoModal(false);
        setSelectedRow({});
    };

    return (
        <Container>
            <div>
                <Typography variant="h5">Clever Chain Screen</Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Here you can view your Clever Chain Screen.
                </Typography>
            </div>
            <div>
                <Row>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="name"
                            label="Name"
                            control={control}
                            rules={{ required: 'This field is required' }}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="city"
                            label="City"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="country"
                            label="Country"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="countryISO"
                            label="Country ISO"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="addressLine1"
                            label="Address Line 1"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="addressLine2"
                            label="Address Line 2"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="registrationNumber"
                            label="Registration Number"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="postalCode"
                            label="Postal Code"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={4} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="monitoring"
                            label="Monitoring"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="selectedLikliHood"
                            label="Selected Likli Hood"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={3} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="category"
                            label="Category"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={3} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="subCategory"
                            label="Sub Category"
                            control={control}
                        />
                    </Col>
                    <Col sm={12} md={3} style={{ marginTop: 10 }}>
                        <FormTextField
                            name="database"
                            label="Database"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col style={{ display: 'flex', justifyContent: 'end'}}>
                        <Button
                            size='large'
                            variant="outline-danger"
                            onClick={reset}>
                            Reset
                        </Button>
                        <Button
                            size='large'
                            variant="outline-success"
                            onClick={handleSubmit(handleClickApplyFilters)}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </div>
            <div>
                <div style={{ paddingBottom: 20, width: '100%' }}>
                    <DataGrid
                        autoHeight
                        density="compact"
                        disableRowSelectionOnClick
                        rowSelection={false}
                        checkboxSelection={false}
                        getRowId={(r) => r.id}
                        loading={isLoading}
                        rows={screens.results}
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false
                            },
                        }}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 15 } },
                        }}
                        pageSizeOptions={[15, 30, 50, 100]}
                    />
                </div>
            </div>

            <CleverChainScreenInfoModal
                onHideModal={onHideInfoModal}
                open={showInfoModal}
                selectedRow={selectedRow}
            />

        </Container>
    );
}
export default CleverChainScreen;
