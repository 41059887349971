import { createAction } from 'redux-actions';
import { instance } from "../../../config/interceptor";

export const cleanupAction = createAction('CLEANUP_CLEVERCHAIN_INFO');

export const cleanup = ()  => async (dispatch) => {
  dispatch(cleanupAction());
}

export const getCleverChainInfoByCompanyNumber = createAction('CLEVERCHAIN_INFO_GET_BY_COMPANY_NUMBER');
export const getCleverChainInfoSuccess = createAction('CLEVERCHAIN_INFO_SUCCESS');
export const getCleverChainInfoError = createAction('CLEVERCHAIN_INFO_ERROR');

export const getCompanyDetail = (companyISO, companyCode, controller) => async (dispatch) => {
  try {
    dispatch(getCleverChainInfoByCompanyNumber());

    const resp = await instance.get(`/v1/CleverChain/GetCompanyDetailsFromCleverchain`, {
      signal: controller.signal,
      params: { CountryISO: companyISO, CompanyNumber: companyCode },
    });

    if (resp.data.isSuccessed) {
      dispatch(getCleverChainInfoSuccess(resp.data));
    } else {
      dispatch(getCleverChainInfoError(resp.data.error || "Unknown error"));
    }
  } catch (e) {
    if (e.name === 'CanceledError') {
      // Gestisci l'aborto della richiesta
      dispatch(getCleverChainInfoError("Request aborted"));
    } else {
      dispatch(getCleverChainInfoError(e.message || "Unknown error"));
    }
  }
};
