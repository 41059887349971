import {
    Button,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {tabIndexProps} from "../../../shared/components/MaterialTabPanel";
import {useDispatch} from "react-redux";
import React, {useRef, useState} from "react";
import {cleanup} from "../../../reducers/cleverChain/cleverChainInfo/cleverChainInfoAction";
import {Col, Row} from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import InfoIcon from '@mui/icons-material/Info';

const CleverChainScreenInfoModal = ({ open, onHideModal, selectedRow }) => {
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleClose = () => {
        onHideModal();
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Annulla la richiesta quando la dialog si chiude
            abortControllerRef.current = null; // Azzera il riferimento
        }
        dispatch(cleanup);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            scroll='paper'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Clever Chain Screen Info</DialogTitle>
            <DialogContent style={{ height: 400 }}>
                {!selectedRow?
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <Tabs
                            value={tab}
                            variant="fullWidth"
                            onChange={handleTabChange}
                            aria-label="icon position tabs example"
                        >
                            <Tab
                                icon={<LocationCityIcon />}
                                iconPosition="start"
                                label="City"
                                {...tabIndexProps(0)}
                            />
                            <Tab
                                icon={<LanguageIcon />}
                                iconPosition="start"
                                label="Country"
                                {...tabIndexProps(1)}
                            />
                            <Tab
                                icon={<InfoIcon />}
                                iconPosition="start"
                                label="Ein"
                                {...tabIndexProps(2)}
                            />
                            <Tab
                                icon={<LocalPostOfficeIcon />}
                                iconPosition="start"
                                label="Postal Code"
                                {...tabIndexProps(3)}
                            />
                            <Tab
                                icon={<InfoIcon />}
                                iconPosition="start"
                                label="Registered Name"
                                {...tabIndexProps(4)}
                            />
                            <Tab
                                icon={<InfoIcon />}
                                iconPosition="start"
                                label="Trading Name"
                                {...tabIndexProps(5)}
                            />
                        </Tabs>

                        <TabPanel value={tab} index={0}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.city?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.city?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.city?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.city?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.country?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.country?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.country?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.country?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.ein?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.ein?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.ein?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.ein?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={3}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.postalCode?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.postalCode?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.postalCode?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.postalCode?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={4}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.registeredName?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.registeredName?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.registeredName?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.registeredName?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={5}>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='internalInfo'
                                        label='Internal Info'
                                        defaultValue={selectedRow.mapped_entity_details?.tradingName?.internalInfo}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteria'
                                        label='Match Criteria'
                                        defaultValue={selectedRow.mapped_entity_details?.tradingName?.matchCriteria}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchCriteriaNumeric'
                                        label='Match Criteria Numeric'
                                        defaultValue={selectedRow.mapped_entity_details?.tradingName?.matchCriteriaNumeric}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col style={{ marginTop: 20 }}>
                                    <TextField
                                        name='matchedEntity'
                                        label='Matched Entity'
                                        defaultValue={selectedRow.mapped_entity_details?.tradingName?.matchedEntity}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPanel>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CleverChainScreenInfoModal;
