import {handleActions} from "redux-actions";
import {searchServiceError, searchServiceRequest, searchServiceSuccess} from "./cleverChainAction";

const defaultState = {
    isSearchingService: false,
    searchServiceError: null,
    searchedService: false,
    cleverChainsData: { companies: [] }
};

export default handleActions(
    {
        [searchServiceRequest](state) {
            return {
                ...state,
                cleverChainsData: { companies: [] },
                isSearchingService: true,
                searchedService: false,
                searchServiceError: null,
            };
        },
        [searchServiceSuccess](state, { payload }) {
            return {
                ...state,
                cleverChainsData: payload.data,
                isSearchingService: false,
                searchedService: "Content loaded",
                searchServiceError: null,
            };
        },
        [searchServiceError](state, { payload }) {
            return {
                ...state,
                cleverChainsData: { companies: [] },
                isSearchingService: false,
                searchedService: false,
                searchServiceError: payload,
            };
        }
    },
    defaultState,
);
