import {createAction} from "redux-actions";
import {instance} from "../../config/interceptor";

export const searchScreenRequest = createAction('SEARCH_SCREEN_REQUEST');
export const searchScreenSuccess = createAction('SEARCH_SCREEN_SUCCESS');
export const searchScreenError = createAction('SEARCH_SCREEN_ERROR');

export const searchScreen = (params) => async (dispatch) => {
    try {
        dispatch(searchScreenRequest());
        const resp = await instance.get(`/v1/CleverChain/GetCompanyScreenFromCleverchain`, { params: params });
        dispatch(searchScreenSuccess(resp.data));
    } catch (e) {
        const message = e.response && e.response.data && e.response.data.description? e.response.data.description : e.message;
        dispatch(searchScreenError(message));
    }
};
