import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colorBorder, colorBackground, colorHover } from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentMenu, cleanup } from "@/reducers/menu/menuAction";
import SimpleLoader from '@/shared/components/SimpleLoader';

const SidebarContent = ({ collapse }) => {

  const dispatch = useDispatch();
  const { menuItems, isLoadingMenu, getCurrentMenuError } = useSelector(({ menu }) => menu);

  useEffect(() => {
    dispatch(getCurrentMenu());

    return () => {
      dispatch(cleanup());
    }
  }, [dispatch]);

  return (
    <SidebarContentWrap collapse={collapse}>
      {isLoadingMenu || !menuItems ? <LoadingContainer><SimpleLoader widthOrHeight={40} /></LoadingContainer> :
        <SidebarBlock collapse={collapse}>
          {menuItems.map((e) =>
            e.applicationMenuItemChildren && e.applicationMenuItemChildren.length ? (
              <SidebarCategory
                key={e.applicationMenuItemId}
                title={e.name}
                collapse={collapse}
                icon={e.fontIcon}
              >
                {e.applicationMenuItemChildren.map((f) =>
                  f.applicationMenuItemChildren && f.applicationMenuItemChildren.length ? (
                    <SidebarCategory
                      key={f.applicationMenuItemId}
                      title={f.name}
                      collapse={collapse}
                      icon={f.fontIcon}
                    >
                      {f.applicationMenuItemChildren?.map((g) => (
                        <SidebarLink
                          key={g?.applicationMenuItemId}
                          route={f.navigationUrl + g.navigationUrl}
                          title={g.name}
                          icon={g.fontIcon}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarLink
                      key={f.applicationMenuItemId}
                      route={e.navigationUrl + f.navigationUrl}
                      title={f.name}
                      icon={f.fontIcon && f.fontIcon !== 'none' ? f.fontIcon : null}
                    />
                  )
                )}
              </SidebarCategory>
            ) : (
              <SidebarLink
                key={e.applicationMenuItemId}
                route={e.navigationUrl}
                title={e.name}
                icon={e.fontIcon}
              />
            )
          )}
        </SidebarBlock>
      }
    </SidebarContentWrap>
  )
};

SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
    props?.collapse &&
    `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
    props?.collapse &&
    `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)} !important;
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

const LoadingContainer = styled.div`
  width: 100% !important;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// endregion
