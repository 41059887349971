import {
    Button,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    DialogTitle, List, ListItem, ListItemText, ListSubheader,
    TextField
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InfoIcon from "@mui/icons-material/Info";
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from "@mui/icons-material/Person";
import {Col, Row} from "react-bootstrap";
import dayjs from "dayjs";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TabPanel, {tabIndexProps} from "../../../shared/components/MaterialTabPanel";
import {getCompanyDetail, cleanup} from "../../../reducers/cleverChain/cleverChainInfo/cleverChainInfoAction";
import {enqueueSnackbar} from "notistack";

const CleverChainInfoModal = ({ open, onHideModal, companyISO, companyNumber }) => {
    const dispatch = useDispatch();
    const abortControllerRef = useRef(null);
    const { detail, isLoadingDetail, getDetailError } = useSelector(({ cleverChainInfo }) => cleverChainInfo);
    const [tab, setTab] = useState(0);
    const [mergedAddresses, setMergeAddresses] = useState([]);

    useEffect(() => {
        if (open) {
            // Crea un nuovo AbortController per ogni apertura della dialog
            if (!abortControllerRef.current)
                abortControllerRef.current = new AbortController();

            const fetchData = async () => {
                await dispatch(getCompanyDetail(companyISO, companyNumber, abortControllerRef.current));
            };
            fetchData();
        }
    }, [dispatch, companyISO, companyNumber, open]);

    useEffect(() => {
        if (getDetailError) {
            enqueueSnackbar(getDetailError, {variant: "error"});
            onHideModal();
        }
    }, [getDetailError]);

    useEffect(() => {
        mergeAddresses();
    }, [detail]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleClose = () => {
        onHideModal();
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Annulla la richiesta quando la dialog si chiude
            abortControllerRef.current = null; // Azzera il riferimento
        }
        dispatch(cleanup);
    }

    const mergeAddresses = () => {
        let arr = [];
        if (detail?.companyProfile?.companyProfile?.addresses && detail?.companyProfile?.companyProfile?.addresses.some(a => a.addressInOneLine))
            arr.push(...detail.companyProfile.companyProfile.addresses?.filter(a => a.addressInOneLine));
        if (detail?.companyProfile?.companyProfile?.otherAddresses && detail?.companyProfile?.companyProfile?.otherAddresses.some(a => a.addressInOneLine))
            arr.push(...detail?.companyProfile.companyProfile.otherAddresses?.filter(a => a.addressInOneLine));
        if (detail?.companyProfile?.companyProfile?.tradingAddresses && detail?.companyProfile?.companyProfile?.tradingAddresses.some(a => a.addressInOneLine))
            arr.push(...detail?.companyProfile?.companyProfile?.tradingAddresses?.filter(a => a.addressInOneLine));
        setMergeAddresses(arr);
    }

    const stringifyAndExludeNullField = (obj) => {
        let copy = Object.assign({}, obj);
        for(let prop in copy) {
            if (copy[prop] == null)
                delete copy[prop];
        }
        return JSON.stringify(copy, null, 2);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            scroll='paper'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Company Profile Info</DialogTitle>
            <DialogContent style={{ height: 700 }}>
                {isLoadingDetail || !detail ?
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> :
                    <>
                        <Tabs
                            value={tab}
                            variant="fullWidth"
                            onChange={handleTabChange}
                            aria-label="icon position tabs example"
                        >
                            <Tab
                                icon={<InfoIcon />}
                                iconPosition="start"
                                label="General Info"
                                {...tabIndexProps(0)}
                            />
                            <Tab
                                icon={<PersonIcon />}
                                iconPosition="start"
                                label="Directors/Owners"
                                {...tabIndexProps(1)}
                            />
                            <Tab
                                icon={<PaymentIcon />}
                                iconPosition="start"
                                label="Financial Info"
                                {...tabIndexProps(2)}
                            />
                        </Tabs>

                        <TabPanel value={tab} index={0}>
                            <Row>
                                <Col md={6} sm={12} style={{ marginTop: 20 }}>
                                    <TextField
                                        name='companyName'
                                        label='Company Name'
                                        defaultValue={detail.companyProfile.companyProfile.companyName}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col md={6} sm={12} style={{ marginTop: 20 }}>
                                    <TextField
                                        name='sector'
                                        label='Sector'
                                        defaultValue={detail.companyProfile.companyProfile.sector}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={6} sm={12} style={{ marginTop: 20 }}>
                                    <TextField
                                        name='companyRegistrationNumber'
                                        label='Company Registration Number'
                                        defaultValue={detail.companyProfile.companyProfile.companyRegistrationNumber}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                                <Col md={6} sm={12} style={{ marginTop: 20 }}>
                                    <TextField
                                        name='lastUpdatedDate'
                                        label='Last Updated Date'
                                        defaultValue={dayjs(detail.companyProfile.companyProfile.lastUpdatedDate).format("DD/MM/YYYY")}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.companyEmail?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Company Emails</ListSubheader>
                                                    {detail?.companyProfile?.companyProfile?.companyEmail.map((a, index) => (
                                                        <ListItem key={`item-${index}`}>
                                                            <ListItemText primary={a} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.phoneNumber?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Phone Numbers</ListSubheader>
                                                    {detail?.companyProfile?.companyProfile?.phoneNumber.map((a, index) => (
                                                        <ListItem key={`item-${index}`}>
                                                            <ListItemText primary={a} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.websites?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Web Sites</ListSubheader>
                                                    {detail?.companyProfile?.companyProfile?.websites.map((a, index) => (
                                                        <ListItem key={`item-${index}`}>
                                                            <ListItemText primary={a} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { mergedAddresses?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Addresses</ListSubheader>
                                                    {mergedAddresses.map((a, index) => (
                                                        <ListItem key={`address-item-${index}`}>
                                                            <ListItemText primary={a.addressInOneLine} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.activity?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Activities</ListSubheader>
                                                    {detail.companyProfile.companyProfile.activity.map((a, index) => (
                                                        <ListItem key={`activity-item-${index}`}>
                                                            <ListItemText primary={a.description} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.directorAndShareDetails?.directors?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Directors</ListSubheader>
                                                    {detail.companyProfile.companyProfile.directorAndShareDetails.directors.map((d, index) => (
                                                        <ListItem key={`directors-item-${index}`}>
                                                            <ListItemText primary={d.name} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.beneficialOwners?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                <ul>
                                                    <ListSubheader>Beneficial Owners</ListSubheader>
                                                    {detail.companyProfile.companyProfile.beneficialOwners.map((o, index) => (
                                                        <ListItem key={`owner-item-${index}`}>
                                                            <ListItemText primary={o.name} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.ownershipStructure?.owners?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            <li key={`title`}>
                                                    <ul>
                                                        <ListSubheader>Owners</ListSubheader>
                                                        {detail.companyProfile.companyProfile.ownershipStructure.owners.map((o, index) => (
                                                            <ListItem key={`owner-item-${index}`}>
                                                            <ListItemText primary={o.name} />
                                                        </ListItem>
                                                    ))}
                                                </ul>
                                            </li>
                                        </List>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    { detail?.companyProfile?.companyProfile?.extendedGroupStructure?.length > 0 &&
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                maxHeight: 200,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            { detail.companyProfile.companyProfile.extendedGroupStructure.map((s, index) => (
                                                <li key={`group-item-${index}`}>
                                                    <ul>
                                                        <ListSubheader>{`Group ${s.addressLocality}`}</ListSubheader>
                                                        { s.corporateLinkage.map((item) => (
                                                            <ListItem key={`sub-item-${item.code}`}>
                                                                <ListItemText primary={item.description} />
                                                            </ListItem>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </List>
                                    }
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <Row>
                                <Col md={12}>
                                    <pre style={{"padding-right": "0px", "contain": "inline-size", "overflow-x": "scroll"}}>
                                        { detail?.companyProfile?.companyProfile?.financialInformationV2?.latestFinancials?.overview &&
                                            stringifyAndExludeNullField(
                                                detail.companyProfile.companyProfile.financialInformationV2.latestFinancials.overview
                                            )
                                        }
                                    </pre>
                                </Col>
                            </Row>
                        </TabPanel>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CleverChainInfoModal;
