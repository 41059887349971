import { handleActions } from 'redux-actions';
import {
    cleanupAction,
    getCleverChainInfoByCompanyNumber,
    getCleverChainInfoError,
    getCleverChainInfoSuccess
} from "./cleverChainInfoAction";

const defaultState = {
    detail: null,
    isLoadingDetail: true,
    getDetailError: null
};

export default handleActions(
  {
    [getCleverChainInfoByCompanyNumber](state) {
      return {
        ...state,
        detail: null,
        isLoadingDetail: true,
        getDetailError: null,
      };
    },
    [getCleverChainInfoSuccess](state, { payload }) {
      return {
        ...state,
        detail: payload.data,
        isLoadingDetail: false,
        getDetailError: null,
      };
    },
    [getCleverChainInfoError](state, { payload }) {
      return {
        ...state,
        detail: null,
        isLoadingDetail: false,
        getDetailError: payload,
      };
    },
    [cleanupAction](){
      return{
        ...defaultState
      }
    }
  },
  defaultState,
);
