import {handleActions} from "redux-actions";
import {searchScreenError, searchScreenRequest, searchScreenSuccess} from "./cleverChainScreenAction";

const defaultState = {
    isLoading: false,
    screenError: null,
    searchedScreen: false,
    screens: { results: [] }
};

export default handleActions(
    {
        [searchScreenRequest](state) {
            return {
                ...state,
                screens: { results: [] },
                isLoading: true,
                searchedScreen: false,
                screenError: null,
            };
        },
        [searchScreenSuccess](state, { payload }) {
            return {
                ...state,
                screens: payload.data,
                isLoading: false,
                searchedScreen: "Content loaded",
                screenError: null,
            };
        },
        [searchScreenError](state, { payload }) {
            return {
                ...state,
                screens: { results: [] },
                isLoading: false,
                searchedScreen: false,
                screenError: payload,
            };
        }
    },
    defaultState,
);
