import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cleanup, getRoles, getTenants, addUser } from "@/reducers/system/users/addUser/systemAddUserAction";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CardPage, CardPageFilterWrapper, CardPageBody, CardPageTitleWrap, CardPageTitle, CardPageSubhead, CardPageButtonWrap, CardPageGridWrap } from '@/shared/components/MaterialCardPage';
import { FormMultiSelectField } from "@/shared/components/form/material-controls/MultiSelectField";
import { FormTextField } from "@/shared/components/form/material-controls/TextField";
import { FormDateField } from "@/shared/components/form/material-controls/DateField";

import { useForm } from "react-hook-form";
import EmailAndUserName from "@/shared/components/EmailAndUserName";

const TenantAddUser = () => {
    const dispatch = useDispatch();
    const {
        roles, isLoadingRoles, getRolesError,
        isAddingUser, addedUser, addUserError
    } = useSelector(({ systemAddUser }) => systemAddUser);

    const navigate = useNavigate();
    const { handleSubmit, getValues, reset, control, setValue, watch } = useForm();

    useEffect(() => {
        dispatch(getRoles());

        return () => {
            dispatch(cleanup());
        }
    }, [dispatch]);

    useEffect(() => {
        if (getRolesError) {
            enqueueSnackbar(getRolesError, { variant: "error" });
        }
    }, [getRolesError]);

    useEffect(() => {
        if (addUserError) {
            enqueueSnackbar(addUserError, { variant: "error" });
        }
    }, [addUserError]);

    useEffect(() => {
        if (addedUser) {
            enqueueSnackbar(`User created successfuly.`, { variant: "success" });
            navigate("/Settings/TenantUsers");
        }
    }, [addedUser]);

    const onSubmit = (data) => {
        console.log(data);
        dispatch(addUser(data));
    }

    const onClickCancel = () => {
        navigate("/Settings/TenantUsers");
    }

    return (
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle>Add User</CardPageTitle>
                    </CardPageTitleWrap>
                    <EmailAndUserName
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                    />
                    <Row>
                        <Col md={6}>
                            <FormTextField
                                name="firstName"
                                label="First Name"
                                control={control}
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormTextField
                                name="lastName"
                                label="Last Name"
                                control={control}
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormTextField
                                name="birthPlace"
                                label="Birth Place"
                                control={control}
                            />
                        </Col>
                        <Col md={6}>
                            <FormDateField
                                name="birthDate"
                                label="Birthday"
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormTextField
                                name="fiscalCode"
                                label="Fiscal Code"
                                control={control}
                            />
                        </Col>
                        <Col md={6}>
                            <FormTextField
                                name="phoneNumber"
                                label="Phone Number"
                                control={control}
                                type="number"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormMultiSelectField
                                name="roles"
                                label="Roles"
                                control={control}
                                options={roles}
                                rules={{ required: 'This field is required' }}
                            />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col md={12}>
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        onClick={onClickCancel}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                    >
                                        Create
                                    </Button>
                                </Grid>

                            </Grid>
                        </Col>
                    </Row>
                </CardPageBody>
            </CardPage>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddingUser}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default TenantAddUser;