import {createAction} from "redux-actions";
import {instance} from "../../config/interceptor";

export const searchServiceRequest = createAction('SERVICES_SEARCH_REQUEST');
export const searchServiceSuccess = createAction('SERVICES_SEARCH_SUCCESS');
export const searchServiceError = createAction('SERVICES_SEARCH_ERROR');

export const searchService = (params) => async (dispatch) => {
    try {
        dispatch(searchServiceRequest());
        const resp = await instance.get(`/v1/CleverChain/GetCompanyDataFromCleverchain`, { params: params });
        dispatch(searchServiceSuccess(resp.data));
    } catch (e) {
        const message = e.response && e.response.data && e.response.data.description? e.response.data.description : e.message;
        dispatch(searchServiceError(message));
    }
};
